<template>
	<Layout>
		<Header :bordered="false" />
		<!-- prettier-ignore -->
		<h3 class="title is-size-4 mt-3 mb-5"><span class="is-regular">Central de</span> Notificações</h3>
		<section class="notify">
			<span v-for="n in notifications" :key="n.id">
				<h4 class="notify__section">{{ n.name }}</h4>
				<div class="notify__header">
					<div class="columns">
						<span class="column is-12-mobile is-6-tablet is-8-desktop">Tipo</span>
						<span class="column is-12-mobile is-3-tablet is-2-desktop notify__centered pointer no-select" @click="selectAll(n, 'enable')">Plataforma</span>
						<span class="column is-12-mobile is-3-tablet is-2-desktop notify__centered pointer no-select" @click="selectAll(n)">E-mail</span>
					</div>
				</div>
				<article v-for="i in n.notification_templates" :key="i.id" class="notify__item">
					<div class="columns">
						<div class="column is-12-mobile is-6-tablet is-8-desktop">
							<h4 class="notify__title">{{ i.title }}</h4>
							<p class="notify__text">{{ i.description }}</p>
						</div>
						<div class="column is-12-mobile is-3-tablet is-2-desktop notify__centered">
							<b-switch v-model="i.push" @input="setConfig(i)"></b-switch>
						</div>
						<div class="column is-12-mobile is-3-tablet is-2-desktop notify__centered">
							<b-switch v-model="i.email" @input="setConfig(i)"></b-switch>
						</div>
					</div>
				</article>
			</span>
		</section>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Api from '@/services/api'
// import Icon from '@/components/Icon'
// import eventHub from '@/services/eventHub'
// import { create, update } from '@/mixins/modal'
import { successToast, errorToast } from '@/mixins/toast'
// import { mapGetters } from 'vuex'
// import { mapState } from 'vuex'

export default {
	components: {
		Layout,
		Header
		//'svg-icon': Icon
	},
	data() {
		return {
			notifications: []
		}
	},
	methods: {
		async selectAll(n, type) {
			console.log(n, type)

			// n.notification_templates.forEach(n => {
			// 	type ? n.enable = !n.enable : n.mode = !n.mode
			// })
		},
		async getAllNotifications() {
			try {
				const response = await Api.get('notification/findAllGroups')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.notifications = data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async setConfig(n) {
			try {
				const response = await Api.put('notification/mode/' + n.id, { push: n.push, email: n.email })
				const { status } = response
				if (status >= 200) {
					successToast('<strong>' + n.title + '</strong> ' + this.$t('configured_success'))
				} else {
					errorToast('<strong>' + n.title + '</strong> ' + this.$t('configured_error'))
				}
			} catch (e) {
				errorToast('<strong>' + n.title + '</strong> ' + this.$t('configured_error'))
			}
		}
	},
	mounted() {
		this.getAllNotifications()
	}
}
</script>
